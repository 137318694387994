import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  constructor() {}

  /**
   * transform object to http params for pagination
   * @param httpParams
   * @returns
   */
  refactorHttpParams(httpParams) {
    let params;
    if (httpParams) {
      params = new HttpParams();
      params = params.set('skip', httpParams?.skip);
      params = params.set('limit', httpParams?.limit);
    } else {
      params = '';
    }
    return params.toString();
  }

  setHttpParams(params?) {
    const httpParams = new HttpParams()
      .set('skip', params.skip)
      .set('limit', params.limit);

    return httpParams;
    // this.getData(httpParams);
  }
  /**
   * generate http params from an object
   * @param params a simple object
   * @returns httpParams
   */
  generateHttpParams(params?) {
    let keys = Object.keys(params);
    let httpParams = new HttpParams();
    keys.forEach((key) => {
      httpParams = httpParams.set(key, params[key]);
    });
    return httpParams;
  }
}
