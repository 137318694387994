import { appInjector } from 'src/app/app.module';
import { PaginationService } from '../services/pagination.service';

export class ActivitiesListing {
  pageSize = 20;
  pageIndex = 0;
  count: number;
  isLimit: boolean;
  paginationService;
  constructor() {
    this.paginationService = appInjector.get(
      PaginationService
    ) as PaginationService;
  }

  nextData() {
    this.pageIndex++;
    this.getData();
  }
  previousData() {
    this.pageIndex--;
    this.getData();
  }

  /**
   * get data
   */
  getData() {
    let params = {
      type: 'difficulty',
      limit: this.pageSize,
      skip: this.pageIndex * this.pageSize,
    };
    params = this.paginationService.generateHttpParams(params);
    this.read(params);
  }
  read(params?) {}
}
