import { Injectable } from '@angular/core';
import { ApiService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ReadService {
  constructor(private apiService: ApiService) {}
  readPublications() {
    return this.apiService.get('/publications');
  }
  readFiles(id) {
    return this.apiService.get('/publications/' + id);
  }
  readStandardActivities(params?) {
    return this.apiService.get('/activities?publish=true', params);
  }
  readProjectActivities(params?) {
    return this.apiService.get('/project-activities?publish=true', params);
  }

  readAcualities(params?) {
    return this.apiService.get('/actualities?publish=true', params);
  }
  readReformProjectActivities(params?) {
    return this.apiService.get(
      '/reform-project-activities?publish=true',
      params
    );
  }
  readReferences() {
    return this.apiService.get('/references');
  }
}
