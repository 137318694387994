import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { appInjector } from 'src/app/app.module';
import { DownloadNewFileComponent } from 'src/app/shared/components';
import { Page } from '../models/classes/page';
import { ApiService } from '../services';
import { ToastrNotificationsService } from '../services/adaptors/toastr-notifications.service';
import { AuthService } from '../services/global/auth.service';
import { PaginationService } from '../services/pagination.service';

export class Listing {
  model;
  apiService;
  paginationService;
  rows = [];
  temp = [];
  page = new Page();
  table;
  user_id;
  authService;
  modalService;
  hotData;
  notify;
  constructor(model) {
    this.model = model;
    this.modalService = appInjector.get(NgbModal) as NgbModal;
    this.apiService = appInjector.get(ApiService) as ApiService;
    this.authService = appInjector.get(AuthService) as AuthService;

    this.paginationService = appInjector.get(
      PaginationService
    ) as PaginationService;
    this.page.pageNumber = 0;
    this.page.size = 20;
    this.authService.getCurrentUser().subscribe((user) => {
      this.user_id = user.id;
    });
    this.notify = appInjector.get(
      ToastrNotificationsService
    ) as ToastrNotificationsService;
  }

  readBublished(params?) {
    this.apiService
      .get(`/${this.model.tableName}/publish`, params)
      .subscribe((data) => {
        this.rows = data;
        console.log(this.rows);
      });
  }
  read(params?) {
    this.apiService.get(this.model.tableName, params).subscribe((data) => {
      this.rows = data.data;
      // push our inital complete list
      this.temp = this.rows;
      console.log(data);
      this.afterRead(data);
    });
  }

  /**
   * paginate
   * @param pageInfo
   */
  paginate(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    const limit = this.page.size;
    const skip = this.page.size * pageInfo.offset;
    const params = {
      limit,
      skip,
    };
    const httpParams = this.paginationService.setHttpParams(params);
    this.read(httpParams);
  }
  setPage(data) {
    this.page.totalElements = data.count;
  }
  afterRead(value) {
    this.setPage(value);
  }

  updateFilter(event, column?) {
    let filterBy = 'title';
    if (column) {
      filterBy = column;
    }
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d[filterBy].toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
  /**
   * open dialog
   */
  open() {
    const modalRef = this.modalService.open(DownloadNewFileComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: 'download-modal',
    });
    modalRef.componentInstance.data = this.model.modalIdentifier;
    modalRef.result.then((res) => {
      console.log(res);
    });
  }

  update(path?) {
    console.log(this.hotData);
    let data = {
      data: this.hotData,
    };
    console.log(data);
    // return;
    this.apiService.post(this.model.tableName, data).subscribe(
      (data) => {
        console.log(data);
        this.notify.success({
          message: 'Modifier avec succès',
        });
      },
      (err) => {
        this.notify.error({
          message:
            "Une erreur s'est produite, veuillez vous assurer que vos données sont correctes",
        });
      }
    );
  }
}
